import type { PostHog } from "posthog-js";
import posthog from "posthog-js";
import type { App } from "vue";

import { cookieNames, isPosthogEnabled, THREE_DAYS_IN_MS } from "@/config/constants";
import { getCookie, removeCookie, setCookie } from "@/util/cookies";
import {
  getPosthogSearchParamOverrides,
  getPosthogSessionStorageOverrides,
  setPosthogSessionStorageOverrides
} from "@/util/utils";

export const initPosthog = (app: App) =>
  app.use({
    install(_options) {
      if (isPosthogEnabled()) {
        app.config.globalProperties.$posthog = posthog.init(import.meta.env.VITE_POSTHOG_KEY, {
          api_host: import.meta.env.VITE_POSTHOG_HOST,
          loaded: function (phInstance: PostHog) {
            const searchParams = new URLSearchParams(window.location.search);
            if (searchParams.has("ingest_id")) {
              console.log("cross domain ingest_id", searchParams.get("ingest_id"));
              phInstance.identify(searchParams.get("ingest_id") ?? undefined);
            } else {
              const cookiesIngestId = getCookie(cookieNames.ingestId);
              if (cookiesIngestId) {
                console.log("ingest_id from cookies: ", cookiesIngestId);
                phInstance.identify(cookiesIngestId ?? undefined);
                removeCookie(cookieNames.ingestId);
              }
            }

            const ingestId = phInstance.get_distinct_id();
            setCookie(cookieNames.ingestId, ingestId, THREE_DAYS_IN_MS);

            // Searching for ph-... query string parameters, and using their values
            // to override  current posthog provided experiments
            let overrideValues: Record<string, string> | null = null;
            const searchParamsOverrideValues = getPosthogSearchParamOverrides(
              Object.fromEntries(new URL(window.location.href).searchParams)
            );

            const sessionStorageOverrideValues = getPosthogSessionStorageOverrides();

            overrideValues = { ...sessionStorageOverrideValues, ...searchParamsOverrideValues };

            if (overrideValues) {
              phInstance.featureFlags.override(overrideValues);

              if (JSON.stringify(overrideValues) !== JSON.stringify(sessionStorageOverrideValues)) {
                setPosthogSessionStorageOverrides(overrideValues);
              }
            }

            phInstance.onFeatureFlags((flags) => {
              const flagWithValues: Record<string, string> = flags.reduce((result, flag) => {
                const flagValue = phInstance.getFeatureFlag(flag);
                if (typeof flagValue === "string") {
                  result[flag] = flagValue;
                } else {
                  result[flag] = flagValue ? "true" : "false";
                }
                return result;
              }, {});

              app.config.globalProperties.$posthogInfo = {
                feature_flags: flagWithValues,
                distinct_id: phInstance.get_distinct_id(),
                session_id: phInstance.get_session_id(),
                user_id: phInstance.get_distinct_id() // after login it's userId.
              };
            });
          }
        });
      }
    }
  });
