export enum DiyPlans {
  Subscription = "subscription",
  OneTimePurchase = "one-time-purchase"
}

export enum ReturnTypes {
  DIY = "diy",
  DFY = "dfy"
}

export enum DfyVersions {
  V1 = "v1",
  V2 = "v2"
}
