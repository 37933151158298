import { BRAND } from "@/config/constants";
import { ROUTE_NAMES } from "@/util/routes/route-names";

export const TAX_SPECIFIC_ROUTES = [
  {
    path: "/",
    name: ROUTE_NAMES.landing,
    component: () => import("@/views/landing.vue"),
    meta: {
      title: `${BRAND} - Landing`,
      layout: "full"
    }
  },
  {
    path: "/legal/:page",
    name: ROUTE_NAMES.legal,
    component: () => import("@/views/legal.vue"),
    meta: {
      title: `${BRAND} - Legal`
    }
  },
  {
    path: "/contact-us",
    name: ROUTE_NAMES.contactUs,
    component: () => import("@/views/contact-us.vue"),
    meta: {
      title: `${BRAND} - Contact Us`
    }
  }
];
