import { cookieNames } from "@/config/constants";
import { useAuthStore } from "@/store/auth.store";
import { useCartStore } from "@/store/cart.store";
import { getDiyStoreDataCookieName, useDiyStore } from "@/store/diy.store";
import { useFormsStore } from "@/store/forms.store";
import { usePaymentsStore } from "@/store/payments.store";
import { useAppStateStore } from "@/store/state.store";
import { removeCookie } from "@/util/cookies";

export const resetStores = () => {
  removeCookie(cookieNames.userUid);
  removeCookie(cookieNames.currentForm);
  removeCookie(cookieNames.userData);
  removeCookie(cookieNames.authTokenExp);
  removeCookie(getDiyStoreDataCookieName());

  const authStore = useAuthStore();
  const cartStore = useCartStore();
  const formsStore = useFormsStore();
  const paymentsStore = usePaymentsStore();
  const appStateStore = useAppStateStore();
  const diyStore = useDiyStore();

  const deviceId = authStore.deviceId;

  authStore.$reset();
  cartStore.$reset();
  formsStore.$reset();
  paymentsStore.$reset();
  appStateStore.$reset();
  diyStore.$reset();

  authStore.setDeviceId(deviceId);
  appStateStore.isLoaded = true;
};
