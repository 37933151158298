<template>
  <picture>
    <source
      v-for="format in formats"
      :key="`${name}.${format}`"
      :type="`image/${format}`"
      :srcset="getSource(format)" />
    <img
      :class="className"
      :fetchpriority="fetchpriority"
      :alt="alt"
      :src="`/assets/images/${name}.${formats[0]}`"
      :width="width"
      :height="height" />
  </picture>
</template>
<script lang="ts">
import type { PropType } from "vue";
import { defineComponent } from "vue";

type ImageFormat = "png" | "jpg" | "webp" | "gif";

export default defineComponent({
  props: {
    name: {
      type: String as PropType<string>,
      required: true
    },
    formats: {
      type: Array as PropType<ImageFormat[]>,
      required: true
    },
    alt: {
      type: String as PropType<string>,
      required: false,
      default: ""
    },
    sizes: {
      type: Number as PropType<1 | 2 | 3 | 4>,
      required: false,
      default: 1
    },
    className: {
      type: String as PropType<string>,
      required: false,
      default: ""
    },
    width: {
      type: Number
    },
    height: {
      type: Number
    },
    fetchpriority: {
      type: String as PropType<"high" | "low" | "auto">,
      required: false,
      default: "auto"
    }
  },
  methods: {
    getSource(format: ImageFormat): string {
      return Array.from({ length: this.sizes }, (_, i) => i + 1)
        .map((size) => `/assets/images/${this.name + (size === 1 ? "" : `@${size}x`)}.${format} ${size}x`)
        .join(",\n");
    }
  }
});
</script>
