import type { PostHog } from "posthog-js";

import { UtilsAPI } from "@/api";
import type {
  UploadContactUsFileRequest,
  UtilsContactUsRequest,
  UtilsCourse,
  UtilsDeductionAnswers,
  UtilsProductShort,
  UtilsTaxPreparer,
  V1DeductionAnswer
} from "@/services/api";
import { getRequestConfigWithToken } from "@/util/auth";
import { getLogger } from "@/util/logger";

const log = getLogger("API: Utils");

export const getDeadlines = async () => {
  log.time("Getting Deadlines");

  const { data } = await UtilsAPI.getDeadlines();

  log.timeEnd("Getting Deadlines");

  return data;
};

export const getDeductionAnswers = async (ph: PostHog | void): Promise<UtilsDeductionAnswers> => {
  log.time("Getting Deductions");

  const requestConfig = await getRequestConfigWithToken(ph);
  const { data } = await UtilsAPI.getDeductionAnswers(undefined, requestConfig);

  log.timeEnd("Getting Deductions");

  return data;
};

export const getDeductions = async (): Promise<V1DeductionAnswer[]> => {
  log.time("Getting Deductions data");

  const { data } = await UtilsAPI.getDeductions();

  log.timeEnd("Getting Deductions data");

  return data;
};

export const storeDeductions = async (
  answers: UtilsDeductionAnswers,
  ph: PostHog | void
): Promise<UtilsDeductionAnswers> => {
  log.time("Storing Deductions data");

  const requestConfig = await getRequestConfigWithToken(ph);
  const { data } = await UtilsAPI.storeDeductions(answers, undefined, requestConfig);

  log.timeEnd("Storing Deductions data");

  return data;
};

export const anonymousContactUs = async (request: UtilsContactUsRequest) => {
  log.time("Getting Anonymous Contact us");

  const { data } = await UtilsAPI.anonymousContactUs(request);

  log.timeEnd("Getting Anonymous Contact us");

  return data;
};

export const getCourses = async (): Promise<UtilsCourse[]> => {
  log.time("Getting courses");

  const { data } = await UtilsAPI.getCourses();

  log.timeEnd("Getting courses");

  return data;
};

export const uploadContactUsFile = async (file: UploadContactUsFileRequest) => {
  log.time("Uploading File");
  const requestConfig = {
    headers: {
      "Content-Type": `multipart/form-data`,
      "X-Skip-Loading-Indicator": true
    }
  };

  const { data } = await UtilsAPI.uploadContactUsFile(file, requestConfig);

  log.timeEnd("Uploading File");

  return data;
};

export const getProductsShort = async (coupon?: string): Promise<UtilsProductShort[]> => {
  log.time("Getting products");

  const { data } = await UtilsAPI.getProductsShort(coupon);

  log.timeEnd("Getting products");

  return data;
};

export const getTaxPreparers = async (ph: PostHog | void): Promise<UtilsTaxPreparer[]> => {
  log.time("Getting Tax Preparers data");

  const requestConfig = await getRequestConfigWithToken(ph);
  const { data } = await UtilsAPI.getTaxPreparers(requestConfig);

  log.timeEnd("Getting Tax Preparers data");

  return data;
};
