<template>
  <svg-icon :aria-label="`${siteHost} logo`" :class="logoClassName" :name="`logos/${siteHost}/${logoName}`" />
</template>
<script lang="ts">
import type { PropType } from "vue";
import { defineComponent } from "vue";

import SvgIcon from "@/components/svg-icon/index.vue";
import { getSiteBrandFromConfig, getSiteHost } from "@/util/helpers";

export default defineComponent({
  name: "ExtLogo",
  components: {
    "svg-icon": SvgIcon
  },
  props: {
    variant: {
      type: String as PropType<string | null>,
      default: null
    }
  },
  computed: {
    logoName(): string {
      return this.variant !== null ? `logo-${this.variant}` : "logo";
    },
    siteHost(): string {
      const brand = getSiteBrandFromConfig();
      const host = getSiteHost(brand);

      return host;
    },
    logoClassName(): string {
      const safeHostName = this.siteHost.replaceAll(".", "");

      return `logo-${safeHostName}`;
    }
  }
});
</script>
<style lang="scss" src="./styles.scss" scoped></style>
