export const getAllCookies = (): Record<string, string> => {
  const cookies: Record<string, string> = {};
  const cookieString = document.cookie;

  if (cookieString) {
    const cookiePairs = cookieString.split(";");

    for (const pair of cookiePairs) {
      const [key, value] = pair.trim().split("=");
      cookies[decodeURIComponent(key)] = decodeURIComponent(value);
    }
  }

  return cookies;
};

export const getAllCookieKeys = (): string[] => {
  return Object.keys(getAllCookies());
};

export const removeCookie = (name: string): void => {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
};

export const getCookie = <T = string>(key: string): null | T => {
  const readCookie = (key: string): string | null => {
    const cookies = document.cookie.split(";");
    const cookie = cookies.find((cookie) => cookie.trim().startsWith(`${key}=`));

    if (!cookie) {
      return null;
    }

    return decodeURIComponent(
      cookie
        .trim()
        .substring(key.length + 1)
        .replace(/\+/g, " ")
    );
  };

  const value = readCookie(key);

  if (!value) {
    return null;
  }

  try {
    return JSON.parse(value) as T;
  } catch {
    // Value is a raw string
    return value as T;
  }
};

export const setCookie = (key: string, value: unknown, timeout: number, domain?: string): void => {
  const storeCookie = (key: string, value: string, timeout: number, domain?: string, sameSite?: string): void => {
    const date: Date = new Date();
    date.setTime(date.getTime() + timeout);

    document.cookie = `${key}=${
      encodeURIComponent(value) + (timeout ? "; Expires=" + date.toUTCString() : "")
    }; Path=/; ${domain ? `Domain=${domain};` : ""} SameSite=${sameSite ?? "Lax"}; host-only-flag=false`;
  };

  // write raw value if value is string, otherwise write JSON
  const valueToWrite = typeof value === "string" ? value : JSON.stringify(value);

  storeCookie(key, valueToWrite, timeout, domain);
};
