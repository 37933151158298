import { defineStore } from "pinia";

import { GetAllPaymentProfiles, GetMerchantPaymentContext, GetPaymentContext } from "@/api/payments";
import type { DtoCart, DtoPaymentProfile, MerchantsMerchantType, PaymentMerchantConfig } from "@/services/api";
import { PaymentPaymentContextRequestContextTypeEnum } from "@/services/api";

interface GetPaymentConfigParams {
  deviceId: string;
  contextType?: PaymentPaymentContextRequestContextTypeEnum;
  cart?: DtoCart;
  merchant?: MerchantsMerchantType;
}

export interface AppState {
  paymentConfig: PaymentMerchantConfig;
  paymentProfiles: DtoPaymentProfile[];
  forceHideFailedSubscriptionAlert: boolean;
}

export const usePaymentsStore = defineStore("payments", {
  state: (): AppState => ({
    paymentConfig: {},
    paymentProfiles: [],
    forceHideFailedSubscriptionAlert: false
  }),
  getters: {
    configuredMerchant(): string | undefined {
      return this.paymentConfig.merchant_code;
    },
    activePaymentProfiles(state): DtoPaymentProfile[] {
      return state.paymentProfiles.filter((profile) => profile.active);
    },
    defaultPaymentProfile(state): DtoPaymentProfile | undefined {
      return state.paymentProfiles.find((profile) => profile.active && profile.is_default);
    },
    getMerchantFromPaymentConfig(state): string {
      const force_merchant = sessionStorage.getItem("force-merchant") as MerchantsMerchantType | null;

      if (force_merchant) return force_merchant;

      return state.paymentConfig.merchant_code ? state.paymentConfig.merchant_code.toLowerCase() : "";
    }
  },
  actions: {
    setPaymentConfig(config: PaymentMerchantConfig | undefined) {
      this.paymentConfig = config ?? {};
    },
    async getPaymentConfig({
      deviceId,
      contextType = PaymentPaymentContextRequestContextTypeEnum.Basic,
      cart
    }: GetPaymentConfigParams) {
      const hostname = window.location.host;
      const config = await GetPaymentContext(hostname, contextType, deviceId, cart);

      this.setPaymentConfig(config);
    },
    async getMerchantPaymentConfig({
      merchant,
      deviceId,
      contextType = PaymentPaymentContextRequestContextTypeEnum.Basic,
      cart
    }: GetPaymentConfigParams) {
      if (!merchant) return;

      const hostname = window.location.host;
      const config = await GetMerchantPaymentContext(merchant, hostname, contextType, deviceId, cart);

      this.setPaymentConfig(config);
    },
    setPaymentProfiles(profiles: DtoPaymentProfile[]) {
      this.paymentProfiles = profiles;
    },
    appendPaymentProfile(profile: DtoPaymentProfile) {
      const profiles = this.paymentProfiles ?? [];
      const profileIndex = profiles.findIndex((p) => p.id === profile.id);

      if (profileIndex !== -1) {
        profiles[profileIndex] = profile;
      } else {
        this.paymentProfiles = [...profiles, profile];
      }
    },
    setMerchant(code: string) {
      this.paymentConfig.merchant_code = code;
    },
    async getPaymentProfiles() {
      const profiles = await GetAllPaymentProfiles();

      this.setPaymentProfiles(profiles);
    },
    setForceHideFailedSubscriptionAlert(state: boolean) {
      this.forceHideFailedSubscriptionAlert = state;
    }
  }
});
