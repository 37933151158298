<template>
  <header class="app-header app-header-filedirectnet" :class="{ 'app-header-grey': isHeaderGrey }">
    <div class="app-header-supheader"></div>
    <b-navbar toggleable="md" container>
      <b-navbar-brand>
        <router-link :to="extensionUrl" exact>
          <logo />
        </router-link>
      </b-navbar-brand>
      <b-navbar-toggle target="nav-collapse">
        <div class="hamburger hamburger-slider">
          <div class="hamburger-box">
            <div class="hamburger-inner"></div>
          </div>
        </div>
      </b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="ms-auto">
          <b-nav-item to="/" exact>Home</b-nav-item>
          <b-nav-item href="/features">Features</b-nav-item>
          <b-nav-item href="/about">About</b-nav-item>
          <b-nav-item v-if="!authorized" to="/registration" :active="isRegistration">Registration</b-nav-item>
          <b-nav-item v-if="!authorized" to="/login">Login</b-nav-item>
          <b-nav-item to="/contact-us">Contact Us</b-nav-item>
          <b-nav-item v-if="authorized" to="/dashboard">Dashboard</b-nav-item>
          <b-nav-item v-if="authorized" to="/logout">Logout</b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </header>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import logo from "@/components/logo/index.vue";

export default defineComponent({
  name: "FileDirectNetHeader",
  components: {
    logo
  },
  props: {
    authorized: {
      type: Boolean,
      default: false
    },
    showLinks: {
      type: Boolean,
      default: true
    },
    extensionUrl: {
      type: String,
      required: true
    },
    blogUrl: {
      type: String,
      required: false
    },
    hideSubmitExtBtn: {
      type: Boolean,
      default: false
    },
    isHeaderGrey: {
      type: Boolean,
      default: false
    },
    isTaxReturnPreparationActive: {
      type: Boolean,
      default: false
    },
    isRegistration: {
      type: Boolean,
      required: false
    }
  }
});
</script>
