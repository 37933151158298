import { ColumnTaxAPI } from "@/api/index";
import type {
  ColumntaxConsentRequest,
  ColumntaxConsentResponse,
  ColumntaxNewColumnTaxFilingRequest,
  ColumntaxReturnURLResponse,
  ColumntaxSettings,
  ColumntaxTaxReturn
} from "@/services/api";
import { getLogger } from "@/util/logger";

const log = getLogger("API: Column tax");

export const columnTaxSignConsentToUsePost = async (
  request: ColumntaxConsentRequest
): Promise<ColumntaxConsentResponse> => {
  log.time("Sign Column Tax Consent To Use");

  const { data } = await ColumnTaxAPI.columntaxSignConsentToUsePost(request);

  log.timeEnd("Sign Column Tax Consent To Use");

  return data;
};

export const getColumnTaxSettings = async (): Promise<ColumntaxSettings> => {
  log.time("Get Column Tax Settings");

  const { data } = await ColumnTaxAPI.getColumnTaxSettings();

  log.timeEnd("Sign Column Tax Consent To Use");

  return data;
};

export const columntaxFilingPost = async (request: ColumntaxNewColumnTaxFilingRequest): Promise<ColumntaxTaxReturn> => {
  log.time("Sign Column Tax Filing");

  const { data } = await ColumnTaxAPI.columntaxFilingPost(request);

  log.time("Sign Column Tax Filing");

  return data;
};

export const columnTaxSettings = async (): Promise<ColumntaxSettings> => {
  log.time("Sign Column Tax settings");

  const { data } = await ColumnTaxAPI.getColumnTaxSettings();

  log.timeEnd("Sign Column Tax settings");

  return data;
};

export const columnTaxSignConsentToDisclosePost = async (
  request: ColumntaxConsentRequest
): Promise<ColumntaxConsentResponse> => {
  log.time("Sign Column Tax Consent To Disclose");

  const { data } = await ColumnTaxAPI.columntaxSignConsentToDisclosePost(request);

  log.timeEnd("Sign Column Tax Consent To Disclose");

  return data;
};

export const getCTReturns = async (): Promise<ColumntaxTaxReturn[]> => {
  log.time("Get Column Tax Returns");

  const { data } = await ColumnTaxAPI.getCTReturns();

  log.timeEnd("Get Column Tax Returns");

  return data;
};

export const columntaxFilingFilingIdPut = async (filingId: string): Promise<ColumntaxTaxReturn> => {
  log.time("Put Column Tax Filing");

  const { data } = await ColumnTaxAPI.columntaxFilingFilingIdPut(filingId);

  log.timeEnd("Put Column Tax Filing");

  return data;
};

export const getCTReturnURL = async (): Promise<ColumntaxReturnURLResponse> => {
  log.time("Get Column Tax Return Url");

  const { data } = await ColumnTaxAPI.getCTReturnURL();

  log.timeEnd("Get Column Tax Return Url");

  return data;
};
