import * as Sentry from "@sentry/vue";
import { defineStore } from "pinia";

import { GetSettings } from "@/api/user";
import { cookieNames } from "@/config/constants";
import type { DtoOfferPageConfig, DtoUserSettingsResponse } from "@/services/api";
import { useAuthStore } from "@/store/auth.store";
import { useCartStore } from "@/store/cart.store";
import { useFormsStore } from "@/store/forms.store";
import { getCookie } from "@/util/cookies";
import { groupBy, nullToEmptyString } from "@/util/helpers";
import { getLogger } from "@/util/logger";

const logger = getLogger("Store: AppState");

export interface AppState {
  isLoading: boolean;
  lastNavigationDirection: string;
  time: boolean;
  isLoaded: boolean;
  isJustPaid: boolean;
  isCaptchaSolved: boolean;
  settings: DtoUserSettingsResponse;
  isSettingsLoaded: boolean;
  isSettingsLoading: boolean;
  isDashboardSidebarOpen: boolean;
  isCustomGtmLoaded: boolean;
  taxPlannerStep: number;
}

export const useAppStateStore = defineStore("appState", {
  state: (): AppState => ({
    isLoading: false,
    isLoaded: false,
    time: false,
    isJustPaid: false,
    isCaptchaSolved: false,
    settings: {
      free_url: "",
      upsell_enabled: false,
      post_sell_enabled: false,
      auto_renew_discount: 0
    },
    isSettingsLoaded: false,
    isSettingsLoading: false,
    lastNavigationDirection: "next",
    isDashboardSidebarOpen: false,
    isCustomGtmLoaded: false,
    taxPlannerStep: 1
  }),
  getters: {
    getIsLoading(): boolean {
      return this.isLoading;
    },
    isStateLoaded(): boolean {
      return this.isLoaded;
    },
    areSettingsLoaded(): boolean {
      return this.isSettingsLoaded;
    },
    getTaxPlannerStep(): number {
      return this.taxPlannerStep;
    },
    funnelGroupedPagesPersonal(state): Record<string, DtoOfferPageConfig[]> {
      const offerPages = state.settings.offer_pages;

      if (!offerPages) return {};

      return groupBy(offerPages.personal, (page: DtoOfferPageConfig) => page.page_placeholder);
    },
    funnelGroupedPagesBusiness(state): Record<string, DtoOfferPageConfig[]> {
      const offerPages = state.settings.offer_pages;

      if (!offerPages) return {};

      return groupBy(offerPages.business, (page: DtoOfferPageConfig) => page.page_placeholder);
    }
  },
  actions: {
    setLoading(isLoading: boolean) {
      this.isLoading = isLoading;
    },
    setSettings(data: DtoUserSettingsResponse) {
      this.settings = data;
      this.isSettingsLoaded = true;
    },
    setTime(time: boolean) {
      this.time = time;
    },
    async initState() {
      // check if you have already initialized the state
      if (this.isLoaded) return null;

      logger.info("State init in progress");

      const authStore = useAuthStore();

      // Only loading forms on non status pages
      if (window.location.href.includes("/extension")) {
        const formStore = useFormsStore();

        logger.info("Loading selected personal form");
        if (formStore.form && formStore.form?.type === "personal" && formStore.form.uid) {
          await formStore.get4868FormData();
        }
        if (formStore.form && formStore.form?.type === "business" && formStore.form.uid) {
          await formStore.get7004FormData();
        }
      }

      if (!!authStore.authToken && !window.location.href.includes("/registration/2")) {
        await this.getSettings();
      }

      logger.info("State init complete");
      // once you are done, save your init state so it doesn't get called again needlessly
      this.isLoaded = true;
    },
    async getSettings(options?: { products?: string[]; formId?: string }) {
      this.isSettingsLoading = true;

      const formsStore = useFormsStore();
      const authStore = useAuthStore();
      const cartStore = useCartStore();

      const formId = options?.formId ?? formsStore.form?.uid;
      const cartPurchases = cartStore.cart.purchases;

      const rdtkclid: string = nullToEmptyString(getCookie(cookieNames.redtrackClickId));
      const funnel = authStore.funnel;

      const timeout = setTimeout(() => {
        if (this.isSettingsLoaded) {
          return;
        }

        Sentry.captureMessage(`Get settings call took more than 10 seconds`);
      }, 10000);

      const url = new URL(window.location.href);
      let purchases: string[] | undefined = url.searchParams.get("purchases")?.split(",");

      if (purchases) {
        purchases = [...(options?.products ?? []), ...purchases];
      } else {
        purchases = options?.products ?? [];
      }

      if (!purchases?.length) {
        purchases = undefined;
      }

      try {
        const settings = await GetSettings(rdtkclid, funnel, formId ?? undefined, cartPurchases, purchases);

        this.setSettings(settings);

        clearTimeout(timeout);
      } catch (e) {
        clearTimeout(timeout);
        throw e;
      } finally {
        this.isSettingsLoading = false;
      }
    },
    async getDefaultSettings() {
      const url = new URL(window.location.href);
      let purchases: string[] | undefined = url.searchParams.get("purchases")?.split(",");

      if (!purchases?.length) {
        purchases = undefined;
      }

      const cartStore = useCartStore();

      const rdtkclid: string = nullToEmptyString(getCookie(cookieNames.redtrackClickId));
      const settings = await GetSettings(rdtkclid, undefined, undefined, cartStore.cart.purchases, purchases);

      this.setSettings(settings);
    },
    toggleDashboardSidebarOpen() {
      this.isDashboardSidebarOpen = !this.isDashboardSidebarOpen;
    },
    setDashboardSidebarOpen(open: boolean) {
      this.isDashboardSidebarOpen = open;
    },
    setJustPaid(paid: boolean) {
      this.isJustPaid = paid;

      if (paid) {
        const cartStore = useCartStore();

        cartStore.initCart();
      }
    },
    setLastNavigationDirection(direction: "prev" | "next") {
      this.lastNavigationDirection = direction;
    },
    setTaxPlannerStep(step: number) {
      this.taxPlannerStep = step;
    }
  }
});
