export const getUUIDv4Polyfill = (): `${string}-${string}-${string}-${string}-${string}` => {
  return (([1e7] as any) + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c: number) =>
    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
  );
};

export const getUUIDv4 = () => {
  if (!crypto || !("randomUUID" in crypto)) {
    return getUUIDv4Polyfill();
  }

  return crypto.randomUUID();
};

export const getSHA1 = async (input: string): Promise<string> => {
  const encoder = new TextEncoder();
  const data = encoder.encode(input);

  const hashBuffer = await crypto.subtle.digest("SHA-1", data);
  const hashArray = Array.from(new Uint8Array(hashBuffer));

  const hashHex = hashArray.map((byte) => byte.toString(16).padStart(2, "0")).join("");

  return hashHex;
};
