export const moneyFormatUSD = (price: number, wholeDollars: boolean = false) => {
  return (price / 100.0).toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
    ...(wholeDollars &&
      Number.isInteger(price / 100.0) && {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      })
  });
};

export const moneyFormat = (rawValue: number) => {
  let val = "";
  const valStr = Math.abs(rawValue).toString();
  for (let i = 1, l = valStr.length; i < l + 1; i++) {
    if ((i === 1 ? i : i - 1) % 3 === 0 && i < l + 1) {
      val = "," + val;
    }
    val = valStr[l - i] + val;
  }
  return val;
};

export const moneyMasks = () => {
  return [
    "$###################",
    "$###,###,###,###",
    "$##,###,###,###",
    "$#,###,###,###",
    "$###,###,###",
    "$##,###,###",
    "$#,###,###",
    "$###,###",
    "$##,###",
    "$#,###",
    "$###",
    "$##"
  ];
};

export const valIfExists = (storeVal: any, val: any) => {
  if (val !== null && val !== undefined && val !== "undefined") {
    return val;
  } else {
    return storeVal;
  }
};
