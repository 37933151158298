<template>
  <div class="suspense-loader">
    <spinner />
  </div>
</template>
<script lang="ts">
import "./styles.scss";

import { defineComponent } from "vue";

import Spinner from "@/components/spinner/index.vue";

export default defineComponent({
  name: "SuspenseLoader",
  components: {
    spinner: Spinner
  }
});
</script>
