import { FIVE_MINUTES_IN_MS } from "@/config/constants";

export interface Options {
  element: HTMLElement;
  loop: boolean;
  timeout: number;
}

export class IdleTimeout {
  protected callback: () => void;
  protected options: Options;
  protected timeoutHandle: number | null = null;
  protected isIdle: boolean = false;
  protected startTime: number = 0;
  protected remainingTime: number = 0;
  protected lastPageX: number = -1;
  protected lastPageY: number = -1;

  /** The input event names. */
  protected eventNames: string[] = [
    "DOMMouseScroll",
    "mousedown",
    "mousemove",
    "mousewheel",
    "MSPointerDown",
    "MSPointerMove",
    "keydown",
    "touchmove",
    "touchstart",
    "wheel"
  ];

  public constructor(callback: () => void, options?: Options) {
    this.callback = callback;
    this.options = {
      element: document.body,
      loop: false,
      timeout: FIVE_MINUTES_IN_MS,
      ...options
    };

    const element = this.options.element;
    this.eventNames.forEach((eventName): void => {
      element.addEventListener(eventName, this.handleEvent);
    });

    this.resetTimeout();
  }

  public pause(): void {
    const remainingTime: number = this.startTime + this.options.timeout - new Date().getTime();
    if (remainingTime <= 0) {
      return;
    }

    this.remainingTime = remainingTime;

    if (this.timeoutHandle) {
      window.clearTimeout(this.timeoutHandle);
      this.timeoutHandle = null;
    }
  }

  public resume(): void {
    if (this.remainingTime <= 0) {
      return;
    }

    this.resetTimeout();
    this.remainingTime = 0;
  }

  public reset(): void {
    this.isIdle = false;
    this.remainingTime = 0;
    this.resetTimeout();
  }

  public destroy(): void {
    const element = this.options.element;
    this.eventNames.forEach((eventName): void => {
      element.removeEventListener(eventName, this.handleEvent);
    });

    if (this.timeoutHandle) {
      window.clearTimeout(this.timeoutHandle);
    }
  }

  protected resetTimeout(): void {
    if (this.timeoutHandle) {
      window.clearTimeout(this.timeoutHandle);
      this.timeoutHandle = null;
    }

    if (this.isIdle && !this.options.loop) {
      return;
    }

    this.timeoutHandle = window.setTimeout((): void => {
      this.handleTimeout();
    }, this.remainingTime || this.options.timeout);

    this.startTime = new Date().getTime();
  }

  protected handleEvent = (event: Event): void => {
    if (this.remainingTime > 0) {
      return;
    }

    if (event.type === "mousemove") {
      const { pageX, pageY } = event as MouseEvent;
      if ((pageX === undefined && pageY === undefined) || (pageX === this.lastPageX && pageY === this.lastPageY)) {
        return;
      }

      this.lastPageX = pageX;
      this.lastPageY = pageY;
    }

    this.resetTimeout();
  };

  protected handleTimeout(): void {
    this.isIdle = true;
    this.resetTimeout();

    this.callback();
  }

  public set loop(value: boolean) {
    this.options.loop = value;
  }

  public set timeout(value: number) {
    this.options.timeout = value;
  }

  public get idle(): boolean {
    return this.isIdle;
  }

  public set idle(value: boolean) {
    if (value) {
      this.handleTimeout();
    } else {
      this.reset();
    }
  }
}

const idleTimeout = (callback: () => void, options?: Options): IdleTimeout => new IdleTimeout(callback, options);

export default idleTimeout;
