<template>
  <slot />
</template>
<script lang="ts">
import * as Sentry from "@sentry/vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "ErrorBoundary",
  errorCaptured(err: unknown, instance, info: string) {
    if (import.meta.env.MODE !== "production") {
      console.error({ err, instance: { ...instance }, info });
    }

    Sentry.captureException(err);

    return;
  }
});
</script>
