export const sessionStorageNames = {
  preFillingUserData: "pre_filling_user_data"
};

export interface PreFillingUserData {
  name?: string;
  contact?: string;
  email?: string;
  phone?: string;
}

export const getSessionStorageObject = <T = string>(key: string): null | T => {
  const value = sessionStorage.getItem(key);

  if (!value) {
    return null;
  }

  try {
    return JSON.parse(value) as T;
  } catch {
    return value as T;
  }
};
