import { defineStore } from "pinia";

import { GetAllActiveUserPurchases } from "@/api/payments";
import { getProductsShort } from "@/api/utils";
import { cookieNames, MONTH_IN_MS, THREE_DAYS_IN_MS } from "@/config/constants";
import type {
  DtoCart,
  DtoCartItem,
  DtoProduct,
  PurchasesPurchase,
  PurchasesUserPurchaseResponse,
  UtilsProductShort
} from "@/services/api";
import { CommonProductTypes } from "@/services/api";
import { useFormsStore } from "@/store/forms.store";
import { useAppStateStore } from "@/store/state.store";
import type { UserData } from "@/store/types";
import { getCookie, removeCookie, setCookie } from "@/util/cookies";
import { getUUIDv4 } from "@/util/crypto";

export const getCartDataCookieName = (): string => {
  const userData = getCookie<UserData>(cookieNames.userData);
  const userId = userData?.id ?? "guest";
  return `${cookieNames.cartData}-${userId}`;
};

export const moveGuestCardToUser = () => {
  const userData = getCookie<UserData>(cookieNames.userData);
  const userId = userData?.id ?? "guest";
  if (userId !== "guest") {
    const guestCard = getCookie<DtoCart>(`${cookieNames.cartData}-guest`);

    if (guestCard) {
      setCookie(getCartDataCookieName(), guestCard, THREE_DAYS_IN_MS);
      removeCookie(`${cookieNames.cartData}-guest`);

      const cookiesStore = useCartStore();
      cookiesStore.getCartFromCookies();
    }
  }
};

moveGuestCardToUser();
const invoiceId = getCookie(cookieNames.invoiceId);
const cartData = getCookie<DtoCart>(getCartDataCookieName());

export interface CartState {
  cart: DtoCart;
  purchases: PurchasesPurchase[];
  availableProductTypes: CommonProductTypes[];
  productsShort: UtilsProductShort[];
}

export const useCartStore = defineStore("cart", {
  state: (): CartState => ({
    cart: cartData ?? {
      invoice_id: invoiceId && invoiceId !== "" ? invoiceId : getUUIDv4(),
      purchases: []
    },
    purchases: [],
    availableProductTypes: [],
    productsShort: []
  }),
  getters: {
    totalCartPrice(state) {
      if (!state.cart) return 0;

      const appStateStore = useAppStateStore();

      let price = 0;

      const purchases: DtoCartItem[] = state.cart.purchases ?? [];
      const usedProducts: string[] = [];
      const hasFilesmart = state.cart.purchases.some(
        (purchase) =>
          appStateStore.settings.products?.find((product) => product.is_available && product.sku == purchase.sku)
            ?.product_type === CommonProductTypes.ProductTypesBundle
      );

      if (!appStateStore.settings.products) return price;

      appStateStore.settings.products.forEach((product: DtoProduct) => {
        if (!purchases.some((purchase) => purchase.sku === product.sku) || usedProducts.includes(product.sku ?? ""))
          return;
        if (
          product.has_trial ||
          product.product_type === CommonProductTypes.ProductTypesAutoExtend ||
          (hasFilesmart && product.product_type === CommonProductTypes.ProductTypesPriority)
        )
          return;

        const productQuantity = purchases.filter((purchase) => purchase.sku === product.sku).length;
        price += (product.price ?? 0) * productQuantity;
      });

      return price;
    },
    // Copy paste of prev method, but used to calculate base price (i.e. price without copuons and discounts
    totalCartOriginalPrice: (state) => {
      if (!state.cart) return 0;

      const appStateStore = useAppStateStore();

      let price = 0;

      const purchases: DtoCartItem[] = state.cart.purchases ?? [];
      const usedProducts: string[] = [];

      if (!appStateStore.settings.products) return price;

      appStateStore.settings.products.forEach((product: DtoProduct) => {
        if (!purchases.some((purchase) => purchase.sku === product.sku) || usedProducts.includes(product.sku ?? ""))
          return;

        price += product.original_price ?? 0;
        usedProducts.push(product.sku ?? "");
      });

      return price;
    },
    getAvailableProductTypes(state): CommonProductTypes[] {
      return state.availableProductTypes;
    }
  },
  actions: {
    syncCartData() {
      setCookie(getCartDataCookieName(), this.cart, THREE_DAYS_IN_MS);
    },
    getCartFromCookies() {
      const cartData = getCookie<DtoCart>(getCartDataCookieName());
      this.cart = cartData ?? {
        invoice_id: invoiceId && invoiceId !== "" ? invoiceId : getUUIDv4(),
        purchases: []
      };
    },
    setCartPurchases(cart: DtoCartItem[]) {
      this.cart.purchases = cart;
      this.syncCartData();
    },
    initCart() {
      const invoiceId = getUUIDv4();

      setCookie(cookieNames.invoiceId, invoiceId, MONTH_IN_MS);

      const cartData = {
        purchases: [],
        invoice_id: invoiceId
      };

      this.cart = cartData;
      this.syncCartData();
    },
    async cleanCart() {
      return new Promise<void>((resolve) => {
        this.excludeAllFormProducts();
        this.initCart();

        resolve();
      });
    },
    excludeAllFormProducts() {
      const appStateStore = useAppStateStore();
      const formsStore = useFormsStore();

      const settings = { ...appStateStore.settings };
      const cart = this.cart;

      // Enabling all disabled products instead of requesting newer settings
      // allows avoiding flickering or the offers after settings call returns results
      if (settings?.offer_pages?.[formsStore.form.type]) {
        const offers = settings.offer_pages[formsStore.form.type];
        settings.offer_pages[formsStore.form.type] = offers.map((of) => {
          if (of.products) {
            of.products = of.products.map((pr) => {
              if (cart.purchases?.some((purchase) => purchase.sku === pr.sku)) {
                pr.is_available = true;
              }
              return pr;
            });
          }
          return of;
        });
      }

      this.syncCartData();
      this.setCartPurchases([]);
    },
    excludeFormProducts(payload: DtoCartItem[]) {
      const { purchases } = this.cart;

      this.cart.purchases = [...(purchases ?? [])].filter(
        (purchase) => !payload.some((payload_item) => payload_item.sku === purchase.sku)
      );

      this.syncCartData();
    },
    updateFormProducts(payload: DtoCartItem[]) {
      const { purchases } = this.cart;

      this.cart.purchases = [
        ...(purchases ?? []),
        ...payload.filter((payload_item) => !purchases.some((purchase) => purchase.sku === payload_item.sku))
      ];

      this.syncCartData();
    },
    setAllActiveUserPurchases(data: PurchasesUserPurchaseResponse) {
      this.purchases = data.purchases;
      this.availableProductTypes = data.available_producttypes;
    },
    setProductsShort(data: UtilsProductShort[]) {
      this.productsShort = data;
    },
    async getProductsShort() {
      const couponArray = getCookie<string[]>(cookieNames.coupons) ?? [];
      let coupon = couponArray.join(";");

      if (!coupon?.length) {
        coupon = new URL(window.location.href).searchParams.getAll("cpn")[0];
      }

      console.log("get products short");

      const shortProducts = await getProductsShort(coupon);

      this.setProductsShort(shortProducts);

      console.log("got products short");
    },
    async getAllActiveUserPurchases() {
      const data = await GetAllActiveUserPurchases();

      if (data) {
        this.setAllActiveUserPurchases(data);
      }
    },
    async updateFormProductsDelayed(payload: DtoCartItem[]) {
      return new Promise<void>((resolve) => {
        this.updateFormProducts(payload);
        setTimeout(() => resolve(), 50);
      });
    },
    async excludeAllFormProductsDelayed() {
      return new Promise<void>((resolve) => {
        this.excludeAllFormProducts();
        setTimeout(() => resolve(), 50);
      });
    },
    async excludeFormProductsDelayed(payload: DtoCartItem[]) {
      return new Promise<void>((resolve) => {
        this.excludeFormProducts(payload);
        setTimeout(() => resolve(), 50);
      });
    },
    getProductWithTags(tags: string[]): DtoProduct | undefined {
      const appStateStore = useAppStateStore();

      return appStateStore.settings.products?.find(
        (product: DtoProduct) =>
          product.tags &&
          tags.every((tag) => product.tags?.includes(tag)) &&
          this.cart.purchases.some((purchase) => purchase.sku === product.sku)
      );
    }
  }
});
