import * as Sentry from "@sentry/vue";

import { DFYReturnsAPI, ReturnsAPI, VaultAPI } from "@/api";
import type { DfySignReturnRequest, DfyTaxReturn, FytBasicFedReturn } from "@/services/api";
import { getLogger } from "@/util/logger";

const log = getLogger("API: Returns");

export const getVaultSections = async () => {
  log.time("Getting vault sections");

  const response = await VaultAPI.getResourceVaultSections();

  if (typeof response !== "object") {
    Sentry.captureMessage(`No vault sections got. Response: ${String(response)}`);
  }

  const { data } = response;

  log.timeEnd("Getting vault sections");

  return data;
};

export const getAllUserReturns = async (): Promise<FytBasicFedReturn[]> => {
  log.time("Getting user returns");

  const { data } = await ReturnsAPI.getAllUserReturns();

  log.timeEnd("Getting user returns");

  return data.flat();
};

export const reFetchUserReturns = async (background: boolean): Promise<FytBasicFedReturn[]> => {
  log.time("Getting fresh user returns");
  const requestConfig = {
    headers: {
      "X-Skip-Loading-Indicator": true
    }
  };

  const { data } = await ReturnsAPI.reFetchUserReturns((background && requestConfig) || undefined);

  log.timeEnd("Getting fresh user returns");

  return data.flat();
};

export const getVaultRecords = async () => {
  log.time("Getting vault records");

  const { data } = await VaultAPI.getResourceVaultRecords();

  log.timeEnd("Getting vault records");

  return data;
};

export const getAllDFYReturns = async (): Promise<DfyTaxReturn[]> => {
  log.time("Get dfy returns");
  const { data } = await DFYReturnsAPI.getAllDFYReturns();

  log.timeEnd("Get dfy returns");

  return data;
};
export const signDFYReturn = async (id: string, signRequest: DfySignReturnRequest): Promise<string> => {
  log.time("Sign DFY Return");
  const { data } = await DFYReturnsAPI.signDFYReturn(id, signRequest);

  log.timeEnd("Sign DFY Return");

  return data;
};
