<template>
  <error-boundary>
    <Suspense>
      <root />
      <template #fallback>
        <suspense-loader />
      </template>
    </Suspense>
  </error-boundary>
</template>
<script lang="ts">
import { defineComponent } from "vue";

import ErrorBoundary from "@/components/error-boundary/index.vue";
import SuspenseLoader from "@/components/suspense-loader/index.vue";
import Root from "@/root.vue";

export default defineComponent({
  name: "App",
  components: {
    "error-boundary": ErrorBoundary,
    "suspense-loader": SuspenseLoader,
    root: Root
  }
});
</script>
