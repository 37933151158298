<template>
  <b-spinner
    :variant="variant"
    :label="label"
    :style="{
      width: `${size}rem`,
      height: `${size}rem`
    }" />
</template>
<script lang="ts">
import type { BaseColorVariant } from "bootstrap-vue-next";
import type { PropType } from "vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "ExtSpinner",
  props: {
    variant: {
      type: String as PropType<keyof BaseColorVariant>,
      default: "primary"
    },
    label: {
      type: String,
      default: "Loading.."
    },
    size: {
      type: Number,
      default: 5
    }
  }
});
</script>
