<template>
  <sticky-bar
    v-show="isVisible"
    id="SB-Success"
    :used="false"
    :hidden="false"
    position="bottom"
    class="sticky-bar-success">
    <div>
      <span class="text"> ✅ Success! Your cart was updated. Complete checkout now! </span>
    </div>
  </sticky-bar>
</template>
<script lang="ts">
import { defineComponent } from "vue";

import StickyBar from "@/components/sticky-bar/sticky-bar.vue";
import { useEventBus } from "@/composables/use-event-bus";

export default defineComponent({
  name: "SBSuccess",
  components: {
    "sticky-bar": StickyBar
  },
  setup() {
    const eventBus = useEventBus();

    return { eventBus };
  },
  data() {
    return {
      remainingTime: 4,
      sbTimer: null as number | null,
      isVisible: false
    };
  },
  created() {
    this.eventBus.$on("sticky-button-click", (_msg) => {
      this.onVisible();
    });
  },
  beforeUnmount() {
    this.eventBus.$off("sticky-button-click", (_msg) => {
      this.onVisible();
    });
    if (this.sbTimer) {
      window.clearInterval(this.sbTimer);
      this.sbTimer = null;
    }
  },
  methods: {
    onVisible(): void {
      if (!this.sbTimer) {
        this.isVisible = true;
        this.sbTimer = window.setInterval(() => {
          if (this.remainingTime === 0) {
            if (this.sbTimer) window.clearInterval(this.sbTimer);
            this.sbTimer = null;
            this.remainingTime = 4;
            this.isVisible = false;
            return;
          }

          this.remainingTime -= 1;
        }, 1000);
      }
    }
  }
});
</script>
