<template>
  <div
    v-show="enabled"
    :id="id"
    class="sticky-bar"
    :class="{
      [`sticky-bar-${position}`]: true,
      'sticky-bar-used': used,
      'd-none': hidden
    }">
    <slot />
  </div>
</template>
<script lang="ts">
import type { PropType } from "vue";
import { defineComponent } from "vue";

import { useEventBus } from "@/composables/use-event-bus";
import { useAppStateStore } from "@/store/state.store";

export default defineComponent({
  name: "StickyBar",
  props: {
    id: {
      type: String,
      default: "sb"
    },
    position: {
      type: String as PropType<"top" | "bottom">,
      default: "top"
    },
    hidden: {
      type: Boolean,
      default: true
    },
    used: {
      type: Boolean,
      default: false
    },
    stickyclass: {
      type: String,
      default: ""
    }
  },
  emits: ["visible"],
  setup() {
    const appStateStore = useAppStateStore();
    const eventBus = useEventBus();

    return { appStateStore, eventBus };
  },
  data() {
    return {
      enabled: false
    };
  },
  computed: {
    stickiesEnabled() {
      return !!this.appStateStore.settings.stickies_enabled;
    }
  },
  watch: {
    stickiesEnabled: {
      immediate: true,
      handler(enabled: boolean) {
        if (!enabled || (enabled && this.enabled)) return;
        this.enabled = true;
      }
    }
  },
  mounted() {
    this.eventBus.$on("funnel-back-click", (stickyId: string, pageName: string) => {
      if (stickyId === this.id) {
        this.$emit("visible", pageName);
      }
    });
  },
  beforeUnmount() {
    this.eventBus.$off("funnel-back-click", (stickyId: string, pageName: string) => {
      if (stickyId === this.id) {
        this.$emit("visible", pageName);
      }
    });
  }
});
</script>
<style lang="scss" src="./styles.scss" scoped></style>
