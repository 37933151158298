import type { RawAxiosRequestConfig } from "axios";
import type { PostHog } from "posthog-js";

import { Logout } from "@/api/oauth";
import { Engine } from "@/config/constants";
import { useAuthStore } from "@/store/auth.store";

import { getEngineFromConfig } from "./helpers";

export const getRequestConfigWithToken = async (ph: PostHog | void): Promise<RawAxiosRequestConfig> => {
  const authStore = useAuthStore();

  await authStore.refreshToken({ ph });

  return {
    headers: {
      Authorization: `Bearer ${authStore.authToken}`
    }
  };
};

export const LogoutHlp = async (
  redirectToHome: boolean = true,
  customRedirectUrl: string | null = null,
  posthog: PostHog | void
) => {
  const isDev = import.meta.env.MODE !== "production";
  const authStore = useAuthStore();

  try {
    await Logout();
  } finally {
    await authStore.logoutAndResetState();

    if (posthog) posthog.reset();

    if (redirectToHome) {
      window.location.href = "/";
    }

    if (customRedirectUrl && customRedirectUrl !== "" && !window.location.href.includes(customRedirectUrl)) {
      window.location.href = customRedirectUrl;
    } else {
      let redirectURL = "/";

      if (getEngineFromConfig() === Engine.FileSmart && !isDev) {
        redirectURL = "https://filesmart.tax/logout";
      }

      window.location.href = redirectURL;
    }
  }
};
