import { PlannerAPI } from "@/api";
import type { ResourcesClientPlannerAnswer, ResourcesClientPlannerEvent } from "@/services/api";
import { getLogger } from "@/util/logger";

const log = getLogger("API: Planner");

export const getPlanner = async () => {
  log.time("Getting Planner");

  const { data } = await PlannerAPI.getPlanner();

  log.timeEnd("Getting Planner");

  return data;
};

export const getCustomerPlannerAnswers = async (): Promise<ResourcesClientPlannerAnswer> => {
  log.time("Getting Customer Planner Answers");

  const { data } = await PlannerAPI.getCustomerPlannerAnswers();

  log.timeEnd("Getting Customer Planner Answers");

  return data;
};

export const saveCustomerPlannerAnswers = async (params: ResourcesClientPlannerAnswer) => {
  log.time("Saving Customer Planner Answers");

  const { data } = await PlannerAPI.saveCustomerPlannerAnswers(params);

  log.timeEnd("Saving Customer Planner Answers");

  return data;
};

export const customerPlannerEvent = async (params: ResourcesClientPlannerEvent) => {
  log.time("Pass customer planner event");

  const { data } = await PlannerAPI.customerPlannerEvent(params);

  log.timeEnd("Pass customer planner event");

  return data;
};
