export const getLogger = (prefix: string): Console => {
  return new Proxy(console, {
    get(target, propertyKey: keyof Console) {
      const originalMethod = target[propertyKey] as unknown;

      if (typeof originalMethod === "function") {
        return (...args: unknown[]) => {
          const prefixedArgs = args.map((arg) => (typeof arg === "string" ? `[${prefix}] ${arg}` : arg));

          return originalMethod.apply(target, prefixedArgs);
        };
      }

      return originalMethod;
    }
  });
};
