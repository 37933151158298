<template>
  <sticky-bar-02 v-if="showStickyBarsOptimize" />
  <sticky-bar-success v-if="showStickyBarsOptimize" />
</template>
<script lang="ts">
import { defineComponent } from "vue";

import SB02 from "@/components/sticky-bar/sb-02.vue";
import SBSuccess from "@/components/sticky-bar/sb-success.vue";
import { useEventBus } from "@/composables/use-event-bus";
import { useAuthStore } from "@/store/auth.store";

export default defineComponent({
  name: "SBFlashDeal",
  components: {
    "sticky-bar-02": SB02,
    "sticky-bar-success": SBSuccess
  },
  setup() {
    const authStore = useAuthStore();
    const eventBus = useEventBus();

    return { authStore, eventBus };
  },
  data() {
    return {
      targetLocalStorage: ["main-offer", "checkout"].includes(window.localStorage.getItem("prev-funnel-page") ?? "")
    };
  },
  computed: {
    targetPage(): boolean {
      return ["/extension/personal/9", "/extension/personal/8", "/extension/personal/10"].some((target) =>
        this.$route.path.includes(target)
      );
    },
    showStickyBarsOptimize(): boolean {
      return this.authStore.isAuthorized && this.targetPage && this.targetLocalStorage;
    }
  },
  mounted() {
    this.eventBus.$on("updateTargetLocalStorage", this.updateTargetLocalStorage);
  },
  beforeUnmount() {
    this.eventBus.$off("updateTargetLocalStorage", this.updateTargetLocalStorage);
  },
  methods: {
    updateTargetLocalStorage(): void {
      const prevButton = window.localStorage.getItem("prev-funnel-page");
      this.targetLocalStorage = ["main-offer", "checkout"].some((rule) => rule === prevButton);
    }
  }
});
</script>
