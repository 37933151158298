import { MerchantsMerchantType } from "@/services/api";

export const getQueryParameter = (param: string, url?: string): string | number | null => {
  if (!url) {
    url = window.location.href;
  }
  const name: string = param.replace(/[[\]]/g, "\\$&");
  const regex: RegExp = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
  const results: RegExpExecArray | null = regex.exec(url);

  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
};

export const createPaymentContextQueryParams = () => {
  const force_merchant = sessionStorage.getItem("force-merchant") as MerchantsMerchantType | null;
  const allowed_merchants = Object.values(MerchantsMerchantType);

  return force_merchant && allowed_merchants.includes(force_merchant) ? { force_merchant } : {};
};
