<template>
  <inline-svg
    :src="svgPath"
    :aria-label="ariaLabel"
    :transform-source="transformSource"
    :class="{ 'svg-icon': icon }" />
</template>
<script lang="ts">
import type { PropType } from "vue";
import { defineComponent } from "vue";
import InlineSvg from "vue-inline-svg";

import { useAppEngine } from "@/composables/use-app-engine";
import { getUUIDv4 } from "@/util/crypto";

export default defineComponent({
  name: "SvgIcon",
  components: {
    InlineSvg
  },
  props: {
    name: {
      type: String as PropType<string | null>,
      required: false,
      default: null
    },
    path: {
      type: String as PropType<string>,
      required: false
    },
    icon: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: true
    },
    ariaLabel: {
      type: String as PropType<string>,
      required: false
    }
  },
  setup() {
    const { isFileSmartEngine } = useAppEngine();

    return { isFileSmartEngine };
  },
  computed: {
    computedName(): string {
      const brandedArrows = [
        "arrow-left-stroke",
        "arrow-right-stroke",
        "processed/list-ok",
        "processed/list",
        "processed/government",
        "processed/government-ok",
        "bbb-circled",
        "million",
        "authorized",
        "locked"
      ];

      const postfix = this.name && this.isFileSmartEngine && brandedArrows.includes(this.name) ? "-filesmart" : "";

      return `${this.name}${postfix}`;
    },
    svgPath(): string {
      if (this.path) return this.path;

      return `/assets/svg/${this.computedName}.svg`;
    }
  },
  methods: {
    transformSource(svg: SVGElement): SVGElement {
      const idPrefix = getUUIDv4();

      const elementsWithId = svg.querySelectorAll("[id]");
      const idMapping = {};

      elementsWithId.forEach((element) => {
        const oldId = element.id;
        const newId = idPrefix + oldId;

        idMapping[oldId] = newId;

        element.id = newId;
      });

      return this.updateIdReferences(svg, idMapping);
    },
    updateIdReferences(svg: SVGElement, idMapping: Record<string, string>): SVGElement {
      const urlReferenceRegex = /url\(#(.*?)\)/g;
      const attributesToCheck = ["href", "xlink:href", "fill", "stroke", "filter", "clip-path", "mask", "style"];

      svg.querySelectorAll("*").forEach((element) => {
        attributesToCheck.forEach((attr) => {
          if (!element.hasAttribute(attr)) return;

          let attrValue = element.getAttribute(attr);

          if (!attrValue) return;

          if (attrValue.startsWith("#") && idMapping[attrValue.substring(1)]) {
            element.setAttribute(attr, `#${idMapping[attrValue.substring(1)]}`);
          }

          if (urlReferenceRegex.test(attrValue)) {
            attrValue = attrValue.replace(urlReferenceRegex, (_match, id) => {
              return `url(#${idMapping[id] || id})`;
            });

            element.setAttribute(attr, attrValue);
          }
        });

        if (element.hasAttribute("style")) {
          let styleValue = element.getAttribute("style");

          if (!styleValue) return;

          styleValue = styleValue.replace(urlReferenceRegex, (_match, id) => {
            return `url(#${idMapping[id] || id})`;
          });

          element.setAttribute("style", styleValue);
        }
      });

      return svg;
    }
  }
});
</script>
<style lang="scss" src="./styles.scss" scoped></style>
