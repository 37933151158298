<template>
  <component :is="footer" :authorized="authStore.isAuthorized" :offer="currentOffer" />
</template>
<script lang="ts">
import { defineComponent } from "vue";

import FooterEfileTaxNet from "@/components/footer/efiletaxnet.vue";
import FooterFileDirectNet from "@/components/footer/filedirectnet.vue";
import FooterFileSmartTax from "@/components/footer/filesmarttax.vue";
import { useAuthStore } from "@/store/auth.store";
import { useFormsStore } from "@/store/forms.store";
import { getSiteBrandFromConfig } from "@/util/helpers";

export default defineComponent({
  name: "AppFooter",
  components: {
    "footer-efiletaxnet": FooterEfileTaxNet,
    "footer-filesmarttax": FooterFileSmartTax,
    "footer-filedirectnet": FooterFileDirectNet
  },
  setup() {
    const formsStore = useFormsStore();
    const authStore = useAuthStore();

    return { formsStore, authStore };
  },
  computed: {
    currentOffer(): string | undefined {
      return this.formsStore.currentOffer;
    },
    footer() {
      const brand = getSiteBrandFromConfig();

      switch (brand.toLowerCase()) {
        case "filesmart.tax":
          return "footer-filesmarttax";
        case "filedirect.net":
          return "footer-filedirectnet";
        default:
          return "footer-efiletaxnet";
      }
    }
  }
});
</script>
