import { ref } from "vue";

import { Engine } from "@/config/constants";
import { getCurrentEngine } from "@/util/helpers";

export const useAppEngine = () => {
  const currentEngine = ref(getCurrentEngine()).value;
  const isFileSmartEngine = ref(currentEngine === Engine.FileSmart).value;
  const isFileDirectEngine = ref(currentEngine === Engine.FileDirect).value;
  const isFileTaxEngine = ref(currentEngine === Engine.FileTax).value;
  const taxPlannerBrand = isFileDirectEngine ? "FileDirect" : "FileSmart™";
  const taxPlannerSite = isFileDirectEngine ? "filedirect.net" : "filesmart.tax";

  return { currentEngine, isFileSmartEngine, isFileDirectEngine, isFileTaxEngine, taxPlannerBrand, taxPlannerSite };
};
