/* eslint-disable @typescript-eslint/prefer-promise-reject-errors */
import * as Sentry from "@sentry/vue";
import type { InternalAxiosRequestConfig, RawAxiosRequestHeaders } from "axios";
import axios from "axios";

import { API_BASE } from "@/config/constants";
import { tricklingProgress } from "@/plugins/trickling";
import { isRouteConsent, router } from "@/router";
import {
  BOIRApi,
  BusinessFormsApi,
  ColumnTaxApi,
  Configuration,
  DFYReturnsApi,
  DocumentsApi,
  FormHelpersApi,
  FYTApi,
  ManagementApi,
  OAuthApi,
  PaymentsApi,
  PersonalFormsApi,
  PlannerApi,
  PurchasesApi,
  ReturnsApi,
  SubscriptionsApi,
  UserApi,
  UserProfileApi,
  UserUtilsApi,
  UtilsApi,
  VaultApi
} from "@/services/api";
import type { BaseAPI } from "@/services/api/base";
import { useAuthStore } from "@/store/auth.store";
import { useAppStateStore } from "@/store/state.store";
import { isAxiosError } from "@/util/error";

let isAlreadyFetchingAccessToken: boolean = false;
let subscribers: Array<(accessToken: string) => void> = [];

const basePath = import.meta.env.MODE !== "production" ? "https://localhost:8080/api/v1" : API_BASE;

const configuration = new Configuration({
  accessToken: () => {
    const authStore = useAuthStore();

    return authStore.authToken;
  }
});

const onAccessTokenFetched = (accessToken: string) => {
  subscribers.forEach((callback) => callback(accessToken));
  subscribers = [];
};

const addSubscriber = (callback: (accessToken: string) => void) => {
  subscribers.push(callback);
};

const axiosApiInstance = axios.create();

axiosApiInstance.interceptors.request.use((config: InternalAxiosRequestConfig) => {
  if (!config.headers["X-Skip-Loading-Indicator"]) {
    const appStateStore = useAppStateStore();

    appStateStore.setLoading(true);
    tricklingProgress.start();
  }

  return config;
});

axiosApiInstance.interceptors.response.use(
  (response) => {
    const appStateStore = useAppStateStore();

    appStateStore.setLoading(false);
    tricklingProgress.done();

    return response;
  },
  async (error) => {
    const appStateStore = useAppStateStore();
    const authStore = useAuthStore();

    appStateStore.setLoading(false);

    tricklingProgress.done();

    const { config, response } = error;

    /**
     * @TODO
     * Remove when fixed
     * https://github.com/axios/axios/issues/5089
     */
    config.headers = JSON.parse(JSON.stringify(config.headers || {})) as RawAxiosRequestHeaders;

    const scope = new Sentry.Scope();
    scope.setTag("activity", "auth");

    const originalRequest = { ...config };

    if (!response && error.message === "Network Error") {
      error.response = {
        data: {
          msg: "Network Error. Please check your connection"
        }
      };

      return Promise.reject(error);
    }

    if (!response) {
      return Promise.reject(error);
    }

    if (response.status === 401 && !originalRequest._retry && !originalRequest.headers["X-Skip-Retry"]) {
      originalRequest._retry = true;

      const retryOriginalRequest = new Promise((resolve) => {
        addSubscriber((accessToken: string) => {
          originalRequest.headers.Authorization = `Bearer ${accessToken}`;
          error.response.config.headers.Authorization = `Bearer ${accessToken}`;

          resolve(axiosApiInstance.request(originalRequest));
        });
      });

      if (!isAlreadyFetchingAccessToken) {
        isAlreadyFetchingAccessToken = true;

        try {
          const { data } = await OAuthAPI.refreshJWTToken();

          if (!data.jwt_token) {
            isAlreadyFetchingAccessToken = false;
            return Promise.reject(error);
          }

          isAlreadyFetchingAccessToken = false;
          authStore.setAuthToken(data.jwt_token);
          authStore.setUserData({ data: data.profile, ph: undefined });

          onAccessTokenFetched(data.jwt_token);
        } catch (error: any) {
          if (!isAxiosError(error) || error.response?.status !== 400) {
            Sentry.captureException(error, scope);
          }

          router.replace({ path: "/login" });
          return;
        }

        return retryOriginalRequest;
      } else {
        return retryOriginalRequest;
      }
    }

    if (response.status === 403) {
      const tokenData = authStore.tokenData;
      const scopes = tokenData?.scopes ?? [];

      if (scopes.includes("consent") && !isRouteConsent()) {
        router.replace({ path: "/registration/consent" });
      }
    }

    if (response.status === 422) {
      router.replace({ path: "/login" });
      Sentry.captureMessage(`422 error happened. Response: ${error}`, scope);
    }

    return Promise.reject(error);
  }
);

const createApi = <T extends BaseAPI>(apiObject: new (...params: ConstructorParameters<typeof BaseAPI>) => T): T => {
  return new apiObject(configuration, basePath, axiosApiInstance);
};

export const DocumentsAPI = createApi(DocumentsApi);
export const PersonalFormsAPI = createApi(PersonalFormsApi);
export const BusinessFormsAPI = createApi(BusinessFormsApi);
export const FormHelpersAPI = createApi(FormHelpersApi);
export const FYTAPI = createApi(FYTApi);
export const ManagementAPI = createApi(ManagementApi);
export const OAuthAPI = createApi(OAuthApi);
export const PaymentsAPI = createApi(PaymentsApi);
export const PurchasesAPI = createApi(PurchasesApi);
export const SubscriptionsAPI = createApi(SubscriptionsApi);
export const PlannerAPI = createApi(PlannerApi);
export const DFYReturnsAPI = createApi(DFYReturnsApi);
export const ReturnsAPI = createApi(ReturnsApi);
export const VaultAPI = createApi(VaultApi);
export const UserUtilsAPI = createApi(UserUtilsApi);
export const UserProfileAPI = createApi(UserProfileApi);
export const UserAPI = createApi(UserApi);
export const UtilsAPI = createApi(UtilsApi);
export const ColumnTaxAPI = createApi(ColumnTaxApi);
export const BoirAPI = createApi(BOIRApi);
