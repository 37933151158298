<template>
  <sticky-bar id="SB" @visible="onVisible">
    <div>
      <span class="text"> 🚨 FLASH DEAL 🚨 Submit NOW &amp; file your full Tax Return for FREE! </span>
      <span v-if="timer" class="timer">
        Ends in <span class="tick">{{ formatTime(remainingTime) }}</span>
      </span>
    </div>
  </sticky-bar>
</template>
<script lang="ts">
import { defineComponent } from "vue";

import StickyBar from "@/components/sticky-bar/sticky-bar.vue";

export default defineComponent({
  name: "SB",
  components: {
    "sticky-bar": StickyBar
  },
  data() {
    return {
      remainingTime: 59,
      timer: null as number | null
    };
  },
  beforeUnmount() {
    if (this.timer) {
      window.clearInterval(this.timer);
      this.timer = null;
    }
  },
  methods: {
    formatTime(seconds: number): string {
      const formattedSeconds = seconds.toString().padStart(2, "0");

      return `00:${formattedSeconds}`;
    },
    onVisible(): void {
      if (!this.timer) {
        this.timer = window.setInterval(() => {
          if (this.remainingTime === 0) {
            if (this.timer) window.clearInterval(this.timer);
            this.timer = null;
            this.remainingTime = 59;
            return;
          }

          this.remainingTime -= 1;
        }, 1000);
      }
    }
  }
});
</script>
